var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tab-intro'), _c('section', {
    staticClass: "page-section"
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100",
    attrs: {
      "src": "/images/sub/company/org/org.svg",
      "aspect-ratio": 1200 / 596,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto",
    attrs: {
      "src": "/images/sub/company/org/org-mo.svg",
      "max-width": "672",
      "alt": 672 / 876,
      "contain": ""
    }
  }), _c('v-sheet', {
    staticClass: "mt-10 mt-md-0"
  }, [_c('v-row', {
    staticClass: "org",
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.org, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "2"
      }
    }, [_c('div', {
      staticClass: "org__head"
    }, [_vm._v(" " + _vm._s(item.head) + " ")]), _vm._l(item.buttons, function (button, index) {
      return _c('org-btn', {
        key: index,
        attrs: {
          "tit": button.tit,
          "link": button.link
        }
      });
    })], 2);
  }), 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }